import { IState, ISortChoice } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';
import * as api from '../api';

type PayloadType = 'initApp' | 'setState' | 'selectFilterChoice' | 'deselectAllFilterChoices' | 'setQuery' | 'search' | 'setPage' | 'selectSortChoice';

export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})

const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
}

export interface SelectFilterChoicePayload {
	choiceId: string;
	selected: boolean
}

export interface SelectSortChoicePayload {
	sortField: string;
	selected: boolean;
}

export const selectFilterChoice: ActionCreator<SelectFilterChoicePayload> = payload => ({
	type: 'selectFilterChoice',
	payload
})

const selectFilterChoiceAction: ActionHandler<SelectFilterChoicePayload> = ({ commit, dispatch }, { payload }) => {
	commit({
		type: 'selectFilterChoice',
		payload
	})
	commit({
		type: 'setPage',
		payload: 0
	})
	dispatch(search(undefined))
}

export const deselectAllFilterChoices: ActionCreator<undefined> = () => ({
	type: 'deselectAllFilterChoices',
	payload: undefined
})

const deselectAllFilterChoicesAction: ActionHandler<undefined> = ({getters, commit, dispatch}) => {
	commit({
		type: 'deselectAllFilterChoices',
		payload: getters.selectedChoices
	});
	commit({
		type: 'setPage',
		payload: 0
	})
	dispatch(search(undefined))
}

export const setQuery: ActionCreator<string> = query => ({
	type: 'setQuery',
	payload: query
})

const setQueryAction: ActionHandler<string> = ({ commit, dispatch }, { payload }) => {
	commit({
		type: 'setQuery',
		payload: payload
	})
	commit({
		type: 'setPage',
		payload: 0
	})
	dispatch(search(undefined))
}

export const search: ActionCreator<undefined> = () => ({
	type: 'search',
	payload: undefined
})

const searchAction: ActionHandler<undefined> = ({ state, getters, commit }) => {
	commit({
		type: 'setState',
		payload: {
			loading: true
		}
	})
	api.search(state.config.searchUrl, {
		query: state.query,
		filter: getters.selectedChoices,
		sort: getters.selectedSort,
		page: state.pagination.current
	})
	.then(result => {
		commit({
			type: 'setState',
			payload: {
				hits: result.hits,
				pagination: result.pagination,
				suggestion: result.suggestion,
				total: result.total
			}
		})
		commit({
			type: 'setState',
			payload: {
				loading: false
			}
		})
	})
	.catch(searchActionError => {
		commit({
			type: 'setState',
			payload: {
				loading: true
			}
		})
		console.error({
			searchActionError
		})
	})
}

export const setPage: ActionCreator<number> = page => ({
	type: 'setPage',
	payload: page
})

const setPageAction: ActionHandler<number> = ({ commit, dispatch }, { payload }) => {
	commit({
		type: 'setPage',
		payload
	})
	dispatch(search(undefined))
}

export const selectSortChoice: ActionCreator<SelectSortChoicePayload> = payload => ({
	type: 'selectSortChoice',
	payload
})

const selectSortChoiceAction: ActionHandler<SelectSortChoicePayload> = ({ commit, dispatch }, { payload }) => {
	commit({
		type: 'selectSortChoice',
		payload
	})
	commit({
		type: 'setPage',
		payload: 0
	})
	dispatch(search(undefined))
}

const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	selectFilterChoice: selectFilterChoiceAction,
	deselectAllFilterChoices: deselectAllFilterChoicesAction,
	setQuery: setQueryAction,
	setPage: setPageAction,
	selectSortChoice: selectSortChoiceAction,
	search: searchAction
}

export default actions;
