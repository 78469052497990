import Vue, { VueConstructor } from 'vue';

import NewsHit from './NewsHit.vue';
import EventHit from './EventHit.vue';
import PageHit from './PageHit.vue';
import PoliticsHit from './PoliticsHit.vue';
import { ISearchHit } from '@/store/state';

export const SearchHitProps = {
	id: String,
	title: String,
	summary: String,
	description: String,
	url: String,
	category: Object,
	published: String,
	languagetag: String,
	type: String as () => any,
}

interface SearchHitProps {
	hit: ISearchHit;
}
export default Vue.extend<{}, {}, {}, SearchHitProps>({
	props: {
		hit: {
			type: Object,
			required: true
		}
	},
	components: {
		NewsHit,
		EventHit,
		PageHit,
		PoliticsHit
	},
	render(h) {
		const linkAttrs = { href: this.hit.url };
		if(this.$store.state.iframe) {
			Object.assign(linkAttrs, {
				rel: 'external'
			})
		}
		const searchHitNoLink = (component: VueConstructor) => h('div', {class: 'kniv-search-hit-link'}, [ h(component, { props: { ...this.hit } }) ]);
		const searchHit = (component: VueConstructor) => h('a', { attrs: linkAttrs, class: 'kniv-search-hit-link'}, [ h(component, { props: { ...this.hit } }) ]);
		switch (this.hit.type) {
			case 'news':
				return searchHit(NewsHit)
			case 'event':
				return searchHit(EventHit)
			case 'politics':
				return searchHitNoLink(PoliticsHit)
			case 'file':
			case 'webpage':
			default:
				return searchHit(PageHit)
		}
	}
});
