




import Vue from 'vue';

interface FilterClearButtonMethods {
	clear(): void
}

interface FilterClearButtonProps {
	text: string
}

export default Vue.extend<{}, FilterClearButtonMethods, {}, FilterClearButtonProps>({
	props: {
		text: String
	},
	methods: {
		clear() {
			this.$emit('click');
		}
	}
})
