




import Vue from 'vue';
import { IState } from '../store/state';

interface HitCountComputed {
	hitText: string;
	show: boolean;
	total: number;
}

export default Vue.extend<{}, {}, HitCountComputed, {}>({
	computed: {
		total(){
			return (this.$store.state as IState).total;
		},
		show() {
			return !this.$store.state.loading;
		},
		hitText() {
			const state = (this.$store.state as IState);
			if(this.total > 0) {
				return `${state.total} träffar på "${state.query}"`
			} else {
				return `Inga träffar hittades på "${state.query}"`;
			}
		}
	}
})
