




































import Vue from 'vue';
import { ISearchHit } from '../../store/state';
import { SearchHitProps } from '.';

export default Vue.extend<{}, {}, {}, ISearchHit>({
  props: {
    id: String,
    title: String,
    summary: String,
    description: String,
    url: String,
    category: Object,
    published: String,
    languagetag: String,
    type: String as () => any,
  },
});
