import { Getter } from "vuex";
import { IState, ISearchConfig, IFilterChoice } from './state';

// const message: Getter<IState, IState> = (state) => `!!${state.message}!!`;

const selectedChoices: Getter<IState, IState> = state => (state as IState<ISearchConfig>).config.extraConfig.filters.reduce<IFilterChoice[]>((choices, filter) => {
	const selectedChoices = filter.choices.filter(choice => choice.selected);
	return [...choices, ...selectedChoices];
}, [])

const selectedSort: Getter<IState, IState> = state => (state as IState<ISearchConfig>).config.extraConfig.sort.find(choice => choice.selected);

export default {
	selectedChoices,
	selectedSort
}
