











import Vue from 'vue';

import SearchFilter from './SearchFilter';

import { IFilter, IState, ISearchConfig } from '../store/state';

interface SearchFilterListComputed {
	filters: IFilter[];
	filterText: string;
	hasSelectedFilter: boolean;
}

interface SearchFilterListData {
	open: boolean
}

export default Vue.extend<SearchFilterListData, {}, SearchFilterListComputed, {}>({
	data() {
		return {
			open: false
		}
	},
	computed: {
		filterText() {
			let extraText = '';
			switch((this.$store.state as IState).config.listType) {
				case 'all':
					extraText = 'sökresultat';
					break;
				case 'news':
					extraText = 'sökresultat';
					break;
				case 'event':
					extraText = 'sökresultat';
					break;
				case 'politics':
					extraText = 'sökresultat på nämnd'
					break;
				default:
					break;
			}
			return `Filtrera ${extraText}`;
		},
		filters() {
			return (this.$store.state as IState<ISearchConfig>).config.extraConfig.filters;
		},
		hasSelectedFilter() {
			return (this.$store.state as IState<ISearchConfig>).config.extraConfig.filters.some(f => {
				return f.choices.some(c => c.selected);
			})
		}
	},
	components: {
		SearchFilter
	}
})
