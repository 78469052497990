








import Vue from 'vue';
import { setQuery } from '@/store/actions';

interface SearchFormData {
	query: string;
}

interface SearchFormComputed {
	stateQuery: string;
}

interface SearchFormMethods {
	search(): void;
}

export default Vue.extend<SearchFormData, SearchFormMethods, SearchFormComputed, {}>({
	data(){
		return {
			query: ''
		}
	},
	created(){
		this.$nextTick(function(){
			this.query = this.stateQuery;
		})	
	},
	computed: {
		stateQuery() {
			return this.$store.state.query;
		}
	},
	watch: {
		stateQuery(newStateQuery) {
			if(newStateQuery !== this.query) {
				this.query = newStateQuery;
			}
		}
	},
	methods: {
		search(){
			this.$store.dispatch(setQuery(this.query));
		}
	}
})
