













import Vue from 'vue';
import { IFilterChoice } from '../../store/state';
import { selectFilterChoice } from '../../store/actions';

interface SearchFilterChoiceComputed {
	selectedClass: any;
	pressed: string;
}

interface SearchFilterChoiceMethods {
	choiceClick(): void;
}

export default Vue.extend<{}, SearchFilterChoiceMethods, SearchFilterChoiceComputed, IFilterChoice>({
	props: {
		id: String,
		name: String,
		values: Array,
		selected: Boolean
	},
	methods: {
		choiceClick(){
			this.$store.dispatch(selectFilterChoice({
				choiceId: this.id,
				selected: !this.selected
			}))
		}
	},
	computed: {
		selectedClass() {
			return {
				'kniv-search-filter__choice--selected': this.selected
			}
		},
		pressed() {
			return this.selected ? 'true' : 'false'
		}
	}
})
