






import Vue from 'vue';
import SearchHit from './SearchHit';
import { ISearchHit, IState, IListConfig } from '../store/state';

interface HitListComputed {
	hits: ISearchHit[],
	listDirection: any
}

export default Vue.extend<{}, {}, HitListComputed, {}>({
	computed: {
		hits() {
			return (this.$store.state as IState).hits;
		},
		listDirection() {
			return (this.$store.state as IState<IListConfig>).config.extraConfig.listStyle;
		}
	},
	components: {
		SearchHit
	}
})
