


























import Vue from "vue";

import HitList from "../HitList.vue";
import SearchFilterList from "../SearchFilterList.vue";
import SearchForm from "../SearchForm.vue";
import ActiveFilter from "../ActiveFilter";
import ListPagination from "../ListPagination.vue";
import SortFilter from "../SortFilter.vue";
import SearchSuggestion from "../SearchSuggestion.vue";
import HitCount from "../HitCount.vue";

import { IState, IListConfig } from "../../store/state";

interface SearchAppComputed {
  type: string;
  loading: boolean;
}

export default Vue.extend<{}, {}, SearchAppComputed, {}>({
  computed: {
    type() {
      return (this.$store.state as IState).config.listType;
    },
    loading() {
      return (this.$store.state as IState).loading;
    }
  },
  watch: {
    loading(isLoading, didLoad) {
      if (didLoad && !isLoading) {
        const { top, left } = (this.$refs
		  .app as Element).getBoundingClientRect();

        window.scrollTo(top, left);
      }
    }
  },
  components: {
    HitList,
    SearchFilterList,
    ActiveFilter,
    SearchForm,
    ListPagination,
    SortFilter,
    SearchSuggestion,
    HitCount
  }
});
