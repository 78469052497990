









import Vue from 'vue';
import queryString from 'query-string';

import HitList from '../HitList.vue';
import { IState, IListConfig } from '../../store/state';

interface ListTypeComputed {
	buttonUrl: string;
	buttonText: string;
	showButton: boolean;
}

export default Vue.extend<{}, {}, ListTypeComputed, {}>({
	components: {
		HitList
	},
	computed: {
		buttonUrl() {
			const state = this.$store.state as IState<IListConfig>;
			return `${state.config.extraConfig.button.url}?${queryString.stringify({ filter: state.config.extraConfig.category.id })}`;
		},
		buttonText() {
			return (this.$store.state as IState<IListConfig>).config.extraConfig.button.text;
		},
		showButton() {
			return (this.$store.state as IState).hits.length > 0;
		}
	}
})
