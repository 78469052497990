import { MutationTree, Mutation } from 'vuex';
import { IState, ISearchConfig, IFilterChoice } from './state';
import { ActionPayload, SelectFilterChoicePayload, SelectSortChoicePayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setState: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}

const selectFilterChoice: MutationHandler<SelectFilterChoicePayload> = (state, { payload }) => {
	(state.config.extraConfig as ISearchConfig).filters.forEach(filter => {
		const choice = filter.choices.find(choice => choice.id === payload.choiceId);
		if(choice) {
			choice.selected = payload.selected
		}
	})
}

const deselectAllFilterChoices: MutationHandler<IFilterChoice[]> = (state, { payload }) => {
	payload.forEach(choice => choice.selected = false);
}

const setQuery: MutationHandler<string> = (state, { payload }) => {
	state.query = payload;
}

const setPage: MutationHandler<number> = (state, { payload }) => {
	state.pagination.current = payload;
}

const selectSortChoice: MutationHandler<SelectSortChoicePayload> = (state, { payload }) => {
	(state.config.extraConfig as ISearchConfig).sort.forEach(sort => {
		if(sort.field === payload.sortField) {
			sort.selected = payload.selected
		} else {
			sort.selected = false
		}
	})
}

const mutations: MutationTree<IState> = {
	setState,
	selectFilterChoice,
	deselectAllFilterChoices,
	setQuery,
	setPage,
	selectSortChoice
}

export default mutations
