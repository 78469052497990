export type ListType = 'all' | 'news' | 'event' | 'politics';
export type ListStyle = 'vertical' | 'horizontal';
export type ShowAs = 'search' | 'list';
export type SearchHitType = 'webpage' | 'news' | 'event' | 'file' | 'politics' | 'unknown';

export interface IState<TConfigType extends IExtraConfig = { 
	showNewsImage: false
 }> {
	hits: ISearchHit[];
	config: IConfig<TConfigType>;
	pagination: IPagination;
	query: string;
	suggestion: string | null;
	total: number;
	loading: boolean;
	iframe: boolean;
}

export interface IFilterChoice {
	id: string;
	name: string;
	values: {
		field: string;
		value: string;
	}[]
	selected: boolean;
}

export interface IFilter {
	name: string;
	choices: IFilterChoice[];
}

export interface ISortChoice {
	field: string;
	name: string;
	selected: boolean;
}

export interface ICategory {
	name: string;
	id: string;
}

export interface IListButton {
	text: string;
	url: string;
}

export interface IPagination {
	current: number;
	max: number;
}

export interface ISearchHit {
	id: string;
	title: string;
	summary: string;
	description: string;
	url: string;
	category: ISearchHitCategory;
	published: string;
	languagetag: string;
	type: SearchHitType;
}

export interface ISearchHitImage {
	url: string;
	alt?: string;
}

export interface ISearchHitCategory {
	icon: string;
	name: string;
}
export interface INewsSearchHit extends ISearchHit {
	highlighted: boolean;
	image?: ISearchHitImage;
}

export interface IEventSearchHit extends ISearchHit {
	startDay: string;
	startMonth: string;
	startTime: string;
	endDay?: string;
	endMonth?: string;
	endTime?: string;
	location: string;
}
export interface IPoliticsSearchHit extends ISearchHit {
	startDay: string;
	startMonth: string;
	startTime: string;
	endTime: string;
	location: string;
	summonLink: string;
	webTVLink?: string;
}

export interface IWebpageSearchHit extends ISearchHit {}

export interface IFileSearchHit extends ISearchHit {}

export interface IExtraConfig {
	showNewsImage: boolean;
}

export interface IListConfig extends IExtraConfig {
	category: ICategory;
	button: IListButton;
	listStyle: ListStyle;
}

export interface ISearchConfig extends IExtraConfig {
	filters: IFilter[];
	sort: ISortChoice[];
}

export interface IConfig<TConfig extends IExtraConfig = {
	showNewsImage: false
}> {
	archives: string[];
	listType: ListType;
	showAs: ShowAs;
	listAmount: number;
	extraConfig: TConfig;
	searchUrl: string;
}

const deafultState: IState = {
	hits: [],
	config: {
		archives: [],
		extraConfig: {
			showNewsImage: false
		},
		listAmount: 0,
		listType: 'all',
		showAs: 'list',
		searchUrl: ''
	},
	pagination: {
		current: 0,
		max: 0
	},
	query: '',
	total: 0,
	suggestion: null,
	loading: false,
	iframe: false
};

export default deafultState;
