
















import Vue from 'vue';
import { ISortChoice, IState, ISearchConfig } from '../store/state';
import { selectSortChoice } from '../store/actions';

interface SortFilterData {
	show: boolean;
	selectedChoice?: ISortChoice;
}

interface SortFilterComputed {
	sort: ISortChoice[];
}

export default Vue.extend<SortFilterData, {}, SortFilterComputed, {}>({
	data(){
		return {
			show: false,
			selectedChoice: undefined
		}
	},
	watch: {
		selectedChoice: {
			handler(newChoice: ISortChoice, oldChoice?: ISortChoice){
				this.show = false;
				if(newChoice.field !== (oldChoice && oldChoice.field)) {
					this.$store.dispatch(selectSortChoice({
						sortField: newChoice.field,
						selected: true
					}))
				}
			},
			deep: true
		}
	},
	computed: {
		sort() {
			return (this.$store.state as IState<ISearchConfig>).config.extraConfig.sort;
		}
	},
	created(){
		this.$nextTick(() => {
			this.selectedChoice = this.$store.getters.selectedSort
		})
	}
})
