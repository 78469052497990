









import Vue from 'vue';

import FilterClearButton from './FilterClearButton.vue';
import { IFilterChoice, IState, ISearchConfig } from '@/store/state';
import { selectFilterChoice, deselectAllFilterChoices } from '../../store/actions';

interface SearchFilterActiveComputed {
	selectedChoices: IFilterChoice[];
}

interface SearchFilterActiveMethods {
	clearFilter(choiceId: string): void;
	clearAllFilter(): void;
}

export default Vue.extend<{}, SearchFilterActiveMethods, SearchFilterActiveComputed, {}>({
	computed: {
		selectedChoices() {
			return this.$store.getters.selectedChoices;
		}
	},
	methods: {
		clearFilter(choiceId) {
			this.$store.dispatch(selectFilterChoice({
				choiceId,
				selected: false
			}))
		},
		clearAllFilter(){
			this.$store.dispatch(deselectAllFilterChoices(undefined));
		}
	},
	components: {
		FilterClearButton
	}
})
