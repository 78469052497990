













































import Vue from 'vue';
import { INewsSearchHit, IState } from '../../store/state';

interface NewsHitComputed {
  newsItemClass: any;
  showImage: boolean;
}

export default Vue.extend<{}, {}, NewsHitComputed, INewsSearchHit>({
  props: {
    id: String,
    title: String,
    summary: String,
    description: String,
    url: String,
    category: Object,
    published: String,
    type: String as () => any,
    highlighted: Boolean,
    image: Object,
    languagetag: String,
  },
  computed: {
    newsItemClass() {
      return {
        'kniv-search-hit--highlighted': this.highlighted,
        'kniv-search-hit--image': this.showImage,
      };
    },
    showImage() {
      return (this.$store.state as IState).config.extraConfig.showNewsImage;
    },
  },
});
