




































import Vue from 'vue';
import { IEventSearchHit } from '../../store/state';
import { SearchHitProps } from '.';

interface EventSearchHitComputed {
  headerText: string;
  dateText: string;
  timeText: string;
}

export default Vue.extend<{}, {}, EventSearchHitComputed, IEventSearchHit>({
  props: {
    id: String,
    title: String,
    summary: String,
    description: String,
    url: String,
    category: Object,
    published: String,
    type: String as () => any,
    startDay: String,
    startMonth: String,
    startTime: String,
    location: String,
    endDay: String,
    endMonth: String,
    endTime: String,
    languagetag: String,
  },
  computed: {
    headerText() {
      if (this.endDay && this.endMonth) {
        return `${this.startDay} ${this.startMonth}-${this.endDay} ${
          this.endMonth
        }`;
      }
      return `${this.startDay} ${this.startMonth}`;
    },
    dateText() {
      if (
        this.endDay &&
        this.endMonth &&
        (this.endDay !== this.startDay || this.endMonth !== this.startMonth)
      ) {
        return `${this.startDay} ${this.startMonth} &ndash; ${this.endDay} ${
          this.endMonth
        }`;
      } else {
        return `${this.startDay} ${this.startMonth}`;
      }
    },
    timeText() {
      if (
        this.endTime &&
        this.endTime !== this.startTime &&
        this.endTime !== '00:00'
      ) {
        return ` ${this.startTime} &ndash; ${this.endTime}`;
      } else {
        return ` ${this.startTime}`;
      }
    },
  },
});
