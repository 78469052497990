import Vue from 'vue';

import ListApp from './ListApp.vue';
import SearchApp from './SearchApp.vue';

import { IState } from '@/store/state';

export default Vue.extend({
	components: {
		ListApp,
		SearchApp
	},
	
	render(h) {
		switch((this.$store.state as IState).config.showAs){
			case 'list':
				return h(ListApp);
			case 'search':
			default:
				return h(SearchApp);
		}
	}
})