import axios from 'axios';
import queryString from 'query-string';

import { IFilterChoice, ISortChoice, IState, ISearchConfig } from '@/store/state';

interface ISearchParams {
	query: string;
	filter: IFilterChoice[],
	sort: ISortChoice,
	page: number
}

export const search = (searchUrl: string, params: ISearchParams): Promise<IState<ISearchConfig>> => {
	const url = `${searchUrl}&${queryString.stringify({
		query: params.query,
		filter: params.filter.map(filter => filter.id).join(','),
		sort: params.sort.field,
		page: params.page,
		dummyParam: true
	})}`;

	window.history.replaceState({}, document.title, url);

	return process.env.NODE_ENV === 'development' ? 
		Promise.reject() :
		axios.get<IState<ISearchConfig>>(url, {
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Accept': 'application/json'
			}
		})
		.then(result => result.data);
}
	