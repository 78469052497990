







import Vue from 'vue';
import { IState } from '@/store/state';
import { setQuery } from '@/store/actions';

interface SearchSuggestionComputed {
	suggestion: string | null
}

interface SearchSuggestionMethods {
	setSuggestion(): void;
}

export default Vue.extend<{}, SearchSuggestionMethods, SearchSuggestionComputed, {}>({
	computed: {
		suggestion(){
			return (this.$store.state as IState).suggestion;
		}
	},
	methods: {
		setSuggestion() {
			if(this.suggestion) {
				this.$store.dispatch(setQuery(this.suggestion))
			}
		}
	}
})
