









import Vue from 'vue';

import SearchFilterChoice from './SearchFilterChoice.vue';

import { IFilter } from '@/store/state';

export default Vue.extend<{}, {}, {}, IFilter>({
	props: {
		name: String,
		choices: Array
	},
	components: {
		SearchFilterChoice
	}
})
