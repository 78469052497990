




import Vue from "vue";
import SearchApp from "./components/SearchApp";
import { IState } from "./store/state";

// Interfaces for component typechecking!
interface AppData {}

interface AppMethods {}

interface AppComputed {
  loadingClass: any;
  loading: boolean;
}

interface AppProps {}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
  name: "app",
	
  computed: {
    loading() {
      return (this.$store.state as IState).loading;
    },
    loadingClass() {
      return {
        "kniv-search-app--loading": this.loading
      };
    }
  },
  components: {
    SearchApp
  }
});
