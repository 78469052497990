
















































import Vue from 'vue';
import { IPoliticsSearchHit } from '../../store/state';
import { SearchHitProps } from '.';

interface PoliticsSearchHitComputed {
  headerText: string;
}

export default Vue.extend<
  {},
  {},
  PoliticsSearchHitComputed,
  IPoliticsSearchHit
>({
  props: {
    id: String,
    title: String,
    summary: String,
    description: String,
    url: String,
    category: Object,
    published: String,
    type: String as () => any,
    startDay: String,
    startMonth: String,
    startTime: String,
    location: String,
    endTime: String,
    summonLink: String,
    webTVLink: String,
    languagetag: String,
  },
  computed: {
    headerText() {
      return `${this.startDay} ${this.startMonth}`;
    },
  },
});
