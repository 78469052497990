














import Vue from 'vue';
import { IPagination, IState } from '@/store/state';
import { setPage } from '@/store/actions';

interface ListPaginationComputed {
	text: string;
	pagination: IPagination;
	canNext: boolean;
	canPrev: boolean;
}

interface ListPaginationMethods {
	prev(): void;
	next(): void;
}

export default Vue.extend<{}, ListPaginationMethods, ListPaginationComputed, {}>({
	computed: {
		pagination(){
			return (this.$store.state as IState).pagination;
		},
		text(){
			return `Sida ${this.pagination.current + 1} av ${this.pagination.max + 1}`;
		},
		canNext(){
			return this.pagination.current + 1 <= this.pagination.max;
		},
		canPrev(){
			return this.pagination.current - 1 >= 0;
		}
	},
	methods: {
		prev() {
			if(this.canPrev) {
				this.$store.dispatch(setPage(this.pagination.current - 1));
			}
		},
		next() {
			if(this.canNext) {
				this.$store.dispatch(setPage(this.pagination.current + 1));
			}
		}
	}
})
